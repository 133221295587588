*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
body::-webkit-scrollbar{
  width: 8px;
	background-color: #F5F5F5;
}
body::-webkit-scrollbar-thumb{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}
body::-webkit-scrollbar-thumb:hover{
	background-color: #aa3131;
}

.loaderLogo{
  width: 200px;
  animation: rotate 5s infinite linear;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

@media screen and (max-width: 600px) {
  .loaderLogo{
    width: 80px;
  }
}

.App{
  background: black;
}

@media screen and (width: 280px) {
  .App{
    overflow: auto;
  }
}