@import url('https://fonts.googleapis.com/css?family=Roboto:300');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=PT+Sans+Narrow&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

:root{
    --primaryfont: 'Raleway', san-serif;
    --primaryred: red;
    --btnColor: #d92038;
    --primaryBackground: #12161f;
    --textColor: white;
}

.header{
    min-height: 10vh;
    position: relative;
    z-index: 1000;
    background-color: var(--primaryBackground);
}
.toggle{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.5rem 1rem;
    color: var(--textColor);
}
.toggle-button{
    cursor: pointer;
}
    .nav-dropdown{
        position: absolute;
        height: 100vh;
        width: 100%;
        z-index: 100;
        background-color: var(--primaryBackground);
        top: 99%;
        left: -100%;
        transition: 0.4s all ease;
    }
    .nav-dropdown.active{
        left: 0;
        transition: 0.4s all ease;
    }
    .nav-dropdown ul{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 1rem 3rem;
    }
    .nav-dropdown ul li{
        list-style: none;
    }
    .nav-dropdown ul li a{
        text-decoration: none;
        font-size: 2rem;
        font-family: var(--primaryfont);
        font-weight: bold;
        color: var(--textColor);
    }
.logo{
    margin-left: auto;
    margin-right: auto;
    width: 100px;
}
.logo img{
    width: 100%;
}
.socials, .logo{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .nav ul{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.nav li{
    list-style: none;
}
.nav li a{
    text-decoration: none;
    font-size: 1.3rem;
    font-family: var(--primaryfont);
    font-weight: bold;
    color: black;
} */
/* .nav li a::after{
    display:block;
    content: '';
    border-bottom: solid 3px red;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}
.nav li a:hover::after{
    transform: scaleX(1);
} */
.active-nav{
    border-bottom: solid 3px var(--primaryred);
}
.nav-dropdown li a::after{
    display:block;
    content: '';
    border-bottom: solid 3px var(--primaryred);
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}
.nav-dropdown li a:hover::after{
    transform: scaleX(1);
}
.btn{
    width: 20%;
    padding: 1rem 3rem;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: rgb(207, 200, 200);
    text-decoration: none;
    color: black;
}
.btn:hover{
    background-color: var(--btnColor);
    transition: 0.4s ease all;
}
.btn:hover{
    color: var(--textColor);
    transition: 0.5s ease;
}
.socials{
    justify-content: space-evenly;
}
.social{
    cursor: pointer;
}
.social:hover{
    transition: 0.4s all;
}
.fb:hover{
    color: #3b5998;
}
.ig:hover{
    color: #3f729b;
}
.li:hover{
    color: #0072b1;
}
.tw:hover{
    color: #1DA1F2;
}
/* ///////////////////////// Banner /////////////////////////// */
.banner{
    height: 90vh;
    text-align:center;
    color:var(--textColor);
    font-family:'Roboto';
    font-weight:300;
    font-size:32px;
    padding-top:40vh;
    overflow:hidden;
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0,0,0);
    position: relative;
    background-color: var(--primaryBackground);
}
.video-container{
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}
.banner div{
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
}
.banner-content{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
}
/* .div1{
    animation: showup 7s infinite;
}
.div2{
    width:0;
    animation: reveal 7s infinite;
}
.div2 span{
    margin-left:-355px;
    animation: slidein 7s infinite;
}
/* ///////////////////////// Banner /////////////////////////// */
/* ///////////////////////// Services /////////////////////////// */
.card-main{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: var(--primaryBackground);
}
/* .card-main h1{
    text-align: center;
    font-family: 'Anton', sans-serif;
} */
.card-list{
    display: flex;
    padding: 1rem;
    justify-content: space-evenly;
    align-items: center;
}
.card{
    padding: 2rem;
    cursor: pointer;
}
.card img{
    width: 150px;
}
.smol2{
    transform: scale(1.1);
}
.card:hover{
    transition: 0.4s ease;
    transform: scale(1.1);
}
.contactModal{
    position: absolute;
    width: 80%;
    height: auto;
    background: var(--primaryBackground);
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    display: none;
    overflow: auto;

    font-family: var(--primaryfont);
    color: var(--textColor);
    font-weight: bold;
    font-size: larger;
}
.contactModal.activeContact{
    display: initial;
}
/* ///////////////////////// Services /////////////////////////// */
/* ///////////////////////// Form /////////////////////////// */
.form{
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    display: none;
    font-family: var(--primaryfont);
    font-weight: bold;
    z-index: 100;
}
.formClosed{
    text-align: end;
}
.form.activeForm{
    display: initial;
}
.formTimes{
    cursor: pointer;
}
.formTimes:hover{
    color: var(--primaryred);
    transform: scale(1.1);
    transition: 0.2s ease;
}
/* input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #ec5656;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #aa4d4d;
}
textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
} */
/* ///////////////////////// Form /////////////////////////// */
/* ///////////////////////// Quotes /////////////////////////// */
.quotes{
    min-height: 60vh;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: rgb(61, 5, 17);
    padding: 5px;
}
.quoteList{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: var(--primaryfont);
    color: var(--textColor);
}
.quoteList-p{
    width: 90%;
    font-weight: bold;
    text-align: center;
}
/* ///////////////////////// Quotes /////////////////////////// */
/* ///////////////////////// AboutUs /////////////////////////// */
.aboutUs{
    min-height: 100vh;
    background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url('../Screens/about.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
}
/* .company-card{
    width: 50%;
    height: auto;
    text-align: center;
    margin: auto;
    border: none;
    grid-column: 1/2;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
} */
/* .card-body{
    font-family: var(--primaryfont);
    font-weight: bold;
    font-size: 1rem;
    background: var(--primaryBackground);
    color: var(--textColor);
    padding: 3rem 1rem;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
} */
/* .card-header{
    background: transparent;
    z-index: 100;
}
.card-header img{
    width: 130px;
    height: 130px;
    margin-bottom: -10%;
    border-radius: 50%;
} */
.consultancy{
    font-family: var(--primaryfont);
    color: var(--textColor);
    padding: 5px;
    grid-column: 2/3;
    padding: 1rem;
    /* margin-top: auto;
    margin-bottom: auto; */
    margin: auto;
    width: 60%;
}
.consultancy div{
    padding: 1rem;
    text-align: justify;
}
.description{
    grid-column: 1/3;
}
.description p{
    font-size: 1.2rem;
    font-weight: bold;
    font-family: var(--primaryfont);
    color: var(--textColor);
    
    display: flex;
    align-items: center;
    height: 100%;
    text-align: justify;
}
.members{
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
    grid-column: 1/3;
    text-align: center;
    color: var(--textColor);
    font-family: var(--primaryfont);
}
.members-div{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
    gap: 5px;

    font-weight: bold;
}
/* ///////////////////////// AboutUs /////////////////////////// */
/* ///////////////////////// Slider /////////////////////////// */
.slideTag{
    text-decoration: none;
    color: var(--textColor);
}
.main-slide{
    min-height: 30vh;
    padding: 4rem;
    margin: auto;
    overflow: hidden;
}
.main-slide h2{
    font-family: var(--primaryfont);
    font-weight: bolder;
    color: white;
}
.slide {
  transition: 0.3s;
  width: 99%;
  border-radius: 10px;
  font-family: var(--primaryfont);
}
.slide:hover{
    color: rgb(82, 82, 185);
}
.slide img{
    width: 100%;
    height: 300px;
}
.slide-container {
  padding: 2px 16px;
}
.slider{
    padding: 1rem;
}
.newsLoader{
    text-align: center;
}
.slick-prev {
  left: 3% !important;
}
.slick-next {
  right: 3% !important;
}
.slick-next, .slick-prev{
    z-index: 1;
    background: gray;
    border-radius: 100px;
}
/* ///////////////////////// Slider /////////////////////////// */
/* ///////////////////////// Connect /////////////////////////// */
.connect{
    min-height: 100vh;
    position: relative;
    /* background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('hammer.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.particle{
    position: absolute;
    height: 100%;
    width: 100%;
}
.connect p{
    color: var(--textColor);
    font-family: var(--primaryfont);
    font-weight: bolder;
    margin-bottom: 2%;
}
.contactForm{
    width: 40%;
    z-index: 100;
}
/* ///////////////////////// Connect /////////////////////////// */
/* ///////////////////////// Footer /////////////////////////// */
.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primaryBackground);
    color: var(--textColor);
    padding: 2rem 6rem;
    height: 10vh;
    
    font-family: var(--primaryfont);
}
/* ///////////////////////// Footer /////////////////////////// */
/* ///////////////////////// ServiceCard /////////////////////////// */
.our-service{
    min-height: 100vh;
    background: black;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
}
.service-container{
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    font-family: var(--primaryfont);
    background-color: var(--primaryBackground);
    color: var(--textColor);
}
.service-header{
    text-align: center;
    font-size: 30px;
    margin-bottom: 50px;
}
.service-card{
    transition: 0.3s;
}
.service-heading{
    text-align: center;
    padding: 50px 10px;
    color: rgb(180, 27, 27);
}
.service-body{
    padding: 30px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
.service-button{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 40vh; */
}
.service-button button{
    width: 80%;
    background: transparent;
    color: var(--textColor);
    padding: 1rem 3rem;
    border-radius: 50px;
    border: 1px solid #989898;
    font-family: var(--primaryfont);
    font-weight: bold;
    font-size: 0.8rem;
}
/* ///////////////////////// ServiceCard /////////////////////////// */
/* ///////////////////////// Chart /////////////////////////// */
.chart{
    font-weight: bolder;
    width: 60%;
}
/* ///////////////////////// Chart /////////////////////////// */
/* ///////////////////////// Blog /////////////////////////// */
.main-blog{
    background: var(--primaryBackground);
    border: 2px solid black;
    min-height: 90vh;

    max-width: 1440px;
    margin: auto;
    padding: 1rem;

   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
   gap: 20px;
}
.blog{
    width: 100%;
    min-height: 35vh;
    font-family: var(--primaryfont);
    font-weight: bolder;
    color: var(--textColor);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.blog img{
    width: 100%;
    height: 200px;
}
.blog-body{
    padding: 1rem;
    font-size: 1.2rem;
}
.blog-footer{
    padding: 1rem;
}
.blog-footer a{
    text-decoration: none;
    color: var(--textColor);
}
.blog-footer a:hover{
    animation: glow 1s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #e60073;
  }
  
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff;
  }
}
/* ///////////////////////// Blog /////////////////////////// */
/* ///////////////////////// NotFound /////////////////////////// */
.shake-horizontal{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--primaryfont);
    color: var(--textColor);

    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
@keyframes shake-horizontal {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }

    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }

    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
/* ///////////////////////// NotFound /////////////////////////// */


@media screen and (width: 1366px) {
    .quoteList{
        padding: 2rem 3rem;
    }
    .card-header img{
        top: -25%;
    }
    .aboutUs {
        grid-template-rows: repeat(2, auto);
    }
    .text-flicker-in-glow{
        font-size: 1.3rem;
    }
}
@media screen and (width: 1360px){
    /* .aboutUs{
        grid-template-rows: 120vh 65vh;
    } */
}
@media screen and (width: 1176px) {
    /* .aboutUs {
        grid-template-rows: auto auto;
    } */
}
@media screen and (max-width: 1024px) {
    /* .logo{
        grid-column: 2/3;
    }
    .nav, .btn, .socials{
        display: none;
    }
    .toggle{
        display: initial;
    }
    .header{
        position: relative;
    }
    .nav-dropdown{
        position: absolute;
        height: 100vh;
        width: 100%;
        z-index: 100;
        background: #ffffff;
        top: 99%;
        left: -100%;
        transition: 0.4s all ease;
        display: initial;
    }
    .nav-dropdown.active{
        left: 0;
        transition: 0.4s all ease;
    }
    .nav-dropdown ul{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 1rem 3rem;
    }
    .nav-dropdown ul li{
        list-style: none;
    }
    .nav-dropdown ul li a{
        text-decoration: none;
        font-size: 2rem;
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        color: black;
    }
    .links{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.5rem;
    }
    .btn-dropdown{
        width: 90%;
        padding: 10px 15px;
        margin-left: auto;
        margin-right: auto;
        border: none;
        border-radius: 50px;
        font-size: 1.3rem;
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        background-color: rgb(207, 200, 200);

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-dropdown:hover{
        background-color: #d92038;
        transition: 0.4s ease all;
    }
    .btn-dropdown{
        text-decoration: none;
        color: black;
    }
     .btn-dropdown:hover{
         transition: 0.5s ease;
        color: white;
    } */
    .social{
        font-size: 1.2rem;
    }
    .main-slide{
        padding: 1rem;
    }
    .slide {
        width: 100%;
    }
    .form{
        width: 90%;
    }
    .quotes{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2rem 1rem;
        text-align: center;
    }
    .quoteList{
        margin-bottom: 30px;
    }
    .aboutUs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .consultancy{
        width: 100%;
    }
    /* .aboutUs{
        padding: 1rem;
    } */
    /* .company-card{
        height: 30vh;
    } */
    .connect{
        flex-direction: column;
    }
    .contactForm{
        width: 90%;
    }
    .our-service{
        flex-direction: column;
    }
    .service-container{
        width: 90%;
    }
    .banner{
        height: 45vh;
    }
    .banner-content{
        position: absolute;
        top: 20%;
        left: 0;
    }
    .video-container{
        height: 100%;
        width: 100%;
    }
    .chart{
        width: 50%;
    }
    .quotes{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .text-flicker-in-glow{
        font-size: 1rem;
    }
}
@media screen and (width: 1280px) {
    .aboutUs{
        grid-template-rows: auto auto;
    }
    .our-service{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .service-header {
        font-size: 25px;
    }
    .service-button button{
        font-size: 0.6rem;
    }
    .text-flicker-in-glow h1{
        font-size: 3rem;
    }
}
@media screen and (width: 1152px){
    /* .aboutUs{
        grid-template-rows: auto auto;
    } */
}
@media screen and (max-width: 600px) {
    /* .company-card{
        width: 100%;
        height: 40vh;
    }
    .card-header img{
        top: -30%;
        width: 100px;
        height: 100px;
    }
    .consultancy div{
        padding: 2px;
    } */
    .aboutUs{
        padding: 1rem;
    }
    /* .div1 h1{
        font-size: 1.8rem;
    }
    .div2 span{
        font-size: 1rem;
    } */
    .card-list{
        flex-direction: column;
    }
    .social{
        font-size: 1rem;
        padding: 1px;
    }
    /* @keyframes reveal {
        0% {opacity:0;width:0px;}
        20% {opacity:1;width:0px;}
        30% {width:355px;}
        80% {opacity:1;}
        100% {opacity:0;width:355px;}
    } */
    .nav-dropdown ul li a{
        font-size: 1.3rem;
    }
    .links{
        font-size: 1rem;
    }
    .main-slide{
        padding: 1rem;
    }
    .slide {
        width: 100%;
    }
    .slider{
        padding: 0;
    }
    .slick-prev {
        left: 5% !important;
    }
    .slick-next {
        right: 5% !important;
    }
    .form{
        width: 100%;
        height: 100%;
    }
    .jotForm{
        height: 100vh;
        overflow: auto;
    }
    .logo img{
        width: 90%;
    }
    .footer{
        font-size: 0.8rem;
        padding: 10px;
    }
    .connect{
        flex-direction: column;
    }
    .contactForm{
        width: 100%;
    }
    .company-card{
        width: 100%;
    }
    .banner{
        height: 40vh;
    }
    .video-container{
        height: 100%;
        width: 100%;
    }
    .btn{
       padding: 10px;
       font-size: 1rem;
    }
    .chart{
        width: 80%;
    }
    .contactModal {
        height: 100%;
    }
    .text-flicker-in-glow{
        font-size: 0.4rem;
    }
    .numbers{
        flex-direction: column;
    }
}